<template>
  <v-data-table
:mobile-breakpoint="0" 
    :headers="systemClientTableHeaders"
    :items="orders"
    :search="$store.state.search"
    :hide-default-footer="true" :disable-pagination="true"
  >
   
    <template v-slot:[`item.id`]="{ item }">
      <router-link :to="{ name: 'Order', params: { id: item.id } }">{{
        item.id || ""
      }}</router-link>
    </template>

    <template v-slot:[`item.title`]="{ item }">
      <router-link :to="{ name: 'Order', params: { id: item.id } }"><span v-html="item.title"></span></router-link>
    </template>
    

    <template v-slot:[`item.actions`]="{ item }">
      <v-btn
        elevation="0"
        :to="{ name: 'Order', params: { id: item.id } }"
        fab
        x-small
      >
      <v-icon scale="1.3" name="eye" color="black" />

      </v-btn>
    </template>
  </v-data-table>
</template>


<script>
import { mapState } from "vuex";
export default {
  props: {
    orders: {
      type: Array,
    },
  },
  computed: {
    ...mapState("orders", ["systemClientTableHeaders"]),
  },
};
</script>