<template>
  <div class="mb-3 " >
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <div class="mb-3 " v-else>
      <BaseHeader :title="`${$route.params.status} Orders`" ></BaseHeader>

      <div class=" mb-3 border-0 ">
        <!-- table headers  -->
        
        <div class=" card d-flex align-items-center flex-row w-100 justify-content-between  p-3  text-left my-1" style>
          <h2 class="card-title w-50 font-weight-black text-capitalize">
          
        </h2>
          <v-text-field
          v-model="$store.state.search"
            dense
            outlined
            label="Filter orders"
            name="url"
            type="text"
            hide-details
          />
        </div>
        <!-- table headers  -->

        <!-- // admin -->
        <admin-table
          v-if="   userType == userTypes.admin && user.id != 100000"
          :orders="orders"
          :user="user"
          :api_paginated="true"
        ></admin-table>
        <!-- {{user}} -->

        <!-- // client -->
        <client-table
          v-if="user && user.id == 100000"
          :orders="orders"
        ></client-table>

        <!-- // writer -->
        <writer-table
          v-if="userType == userTypes.writer"
          :orders="orders"
        ></writer-table>


        <!-- // editor -->
        <editor-table
          v-if="userType == userTypes.editor"
          :orders="orders"
        ></editor-table>

        <!-- pagination -->


        <div class="card d-flex mb-3 align-items-center flex-row w-100 justify-content-between p-3 mb-3" v-if="orders.length > 0">
          <p class="text-left">
            From {{ filters.from || "" }} to {{ filters.to || "" }} of
            {{ filters.total || "" }} Orders
          </p>
            <div data-v-2bb62016="" class="btn-group">
              <button   @click="navigate(filters.prev_page_url)"  v-if="filters.prev_page_url" class="btn btn-outline-primary btn-lg">← &nbsp; Prev</button>
              <button   class="btn btn-primary btn-lg">On Page {{ filters.current_page || "" }}</button>
              <button   @click="navigate(filters.next_page_url)" v-if="filters.next_page_url" class="btn btn-outline-primary btn-lg">Next &nbsp; →</button>
            </div>
           
        </div>
        <!-- ./pagination -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import AdminTable from "../../components/tables/AdminTable.vue";
import ClientTable from "../../components/tables/ClientTable.vue";
import WriterTable from "../../components/tables/WriterTable.vue";
import EditorTable from "../../components/tables/EditorTable.vue";
import SubadminTable from "../../components/tables/SubadminTable.vue";

export default {
  name: "OrderListing",
  computed: {
    ...mapState("orders", ["orders", "filters"]),
    ...mapGetters("auth", ["userType", "userTypes"]),
    ...mapState("auth", ["user"])

  },

  components: {
    AdminTable,
    ClientTable,
    WriterTable,
    EditorTable,
    SubadminTable,

  },

  data() {
    return {
      loading: false,
    };
  },

  beforeRouteUpdate(to, from, next) {
    this.loading = true;
    let payload = to.params && to.params.status ? to.params.status : "editing";
    this._getOrders(payload)
      .catch((err) => console.log(err))
      .finally(() => (this.loading = false));
    next();
  },

  methods: {
    ...mapActions("orders", ["_getOrders", "_navigate"]),
    ...mapActions("sidebar", ["getSidebarMenu"]),
    navigate(route) {
      this.loading = true;
      this._navigate(route)
        .catch((err) => console.log(err))
        .finally(() => (this.loading = false));
    },
  },

  mounted() {
    this.loading = true;
    // console.log(this.$route.params.status);
    this.getSidebarMenu();
    this._getOrders(this.$route.params.status || "editing")
      .catch((err) => console.log(err))
      .finally(() => (this.loading = false));
  },
};
</script>

<style lang="scss" scoped>
.is-title {
  max-height: 3px !important;
  overflow-y: hidden;
  line-height: 1.5;
  overflow: hidden;
}
</style>
